import { kebabCase } from 'utils/helpers';
const pluralize = require('pluralize');

// Based on: https://theme-ui.com/theme-spec/
// Include a _unit property to automatically return units when this object is converted to CSS custom properties

const spaceBase = 8;
const fontSizeBase = '1rem';
const fontSizeBaseScaleRatio = 1.125;

// TODO: Look into typing _unit explicitly whilst still being able to maintain inferred type of other properties
export const theme = {
  /** color, background-color, border-color */
  colors: {
    white: '#fff',
    black: '#000',
    pink: '#f5a89d',
    pink100: '#f77c77',
    beige: '#b8aa98',
    grey: '#f5f5f5',
    grey100: '#eae6df',
    grey200: '#cacaca',
    yellow: '#f7a706',
    green: '#9bceb1',
    purple: '#b9a1a5',
  },
  /** font-family */
  fonts: {
    heading: `'DM Serif Display', serif`,
    body: `'Montserrat', sans-serif`,
  },
  /** font-size */
  fontSizes: {
    ...generateFontVars(),
  },
  /** font-weight */
  fontWeights: {
    light: 300,
    regular: 400,
    medium: 500,
    bold: 700,
  },
  /** line-height */
  lineHeights: {
    small: 1,
    medium: 1.2,
    large: 1.4,
    xLarge: 1.65,
  },
  /** margin, margin-top, margin-right, margin-bottom, margin-left, padding, padding-top, padding-right, padding-bottom, padding-left, grid-gap, grid-column-gap, grid-row-gap */
  space: {
    _unit: 'px',
    base: spaceBase,
    ...generateSpaceVars(),
  },
  /** letter-spacing */
  letterSpacings: {},
  /** width, height, min-width, max-width, min-height, max-height */
  sizes: {
    _unit: 'px',
    main: 1920,
  },
  /** border, border-top, border-right, border-bottom, border-left */
  borders: {},
  /** border-width */
  borderWidths: {},
  /** border-style */
  borderStyles: {},
  /** border-radius */
  radii: {
    _unit: 'px',
    xSmall: 4,
    small: 10,
    medium: 16,
    large: 18,
  },
  /** box-shadow, text-shadow */
  shadows: {},
  /** z-index */
  zIndices: {},
} as const;

export default theme;

export type Theme = typeof theme;

export const breakpoints = {
  small: '36em',
  medium: '48em',
  large: '62em',
  xLarge: '75em',
};

// Generate font variables based on modular scale
function generateFontVars() {
  const ratioVar = 'var(--font-size-scale-ratio)';

  function create<T extends string>(keys: T[]): { [K in T]: string } {
    return Object.fromEntries(
      keys.reduce(
        (prev, current, index) => {
          const prevSize = `var(--font-size-${kebabCase(prev[index][0])})`;
          return [...prev, [current, `calc(${prevSize} * ${ratioVar})`]];
        },
        [['xxSmall', fontSizeBase]]
      )
    );
  }

  const computedSizes = create([
    'xSmall',
    'small',
    'medium',
    'large',
    'xLarge',
    'xxLarge',
    'xxxLarge',
    'xxxxLarge',
  ]);

  return {
    scaleRatio: fontSizeBaseScaleRatio,
    xxxSmall: `calc(${fontSizeBase} / ${ratioVar})`,
    xxSmall: fontSizeBase,
    ...computedSizes,
  };
}

// Generate space variables based on root spacing unit
function generateSpaceVars() {
  function generate<T extends number>(keys: T[]): { [K in T]: number } {
    const items: { [i: number]: number } = keys.reduce((prev, _, index) => {
      return { ...prev, [index]: (index + 1) * spaceBase };
    }, {});
    return items;
  }
  // Why not use Array.fill() you ask? This way allows inferred typing of object keys i.e. 0: 8, 1: 16
  // Maybe there's a cleaner way to accomplish the same thing?
  return generate([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
}

export function generateCustomPropertyKey(
  themeProperty: keyof Theme,
  key: string | number
) {
  return kebabCase(`--${pluralize(themeProperty, 1)}-${key}`);
}

export function generateCustomPropertiesFromThemeKey(
  themeProperty: keyof Theme,
  unit?: string
) {
  const items = Object.entries(theme[themeProperty])
    .filter(([key]) => key !== '_unit')
    .map(
      ([key, value]) =>
        `${generateCustomPropertyKey(themeProperty, key)}: ${
          value + (unit ? unit : '')
        }`
    );

  if (!items.length) {
    return;
  }

  return items.join(';') + ';';
}

/**
 * Builds a list of custom properties based on the theme object, properties are derived from key names and converted to kebab casing
 */
export function generateCustomPropertiesFromTheme() {
  const properties = Object.entries(theme).map(([key, value]) => {
    return generateCustomPropertiesFromThemeKey(
      key as keyof Theme,
      (value as { [key: string]: string })?._unit
    );
  });

  return properties.join('');
}
