import { AnimatePresence } from 'framer-motion';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Script from 'next/script';
import 'normalize.css';
import { useEffect, useRef } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Hydrate } from 'react-query/hydration';
import GlobalStyle from 'styles/global';
import { isBrowser } from 'utils/helpers';
import { init } from 'utils/sentry';
import * as gtag from '../utils/gtag';

// Init Sentry
init();

interface Props extends AppProps {
  /**
   * Not part of the publicly exposed Next API.
   * Workaround for https://github.com/vercel/next.js/issues/8592
   */
  err: any;
}

function handleExitComplete() {
  if (isBrowser) {
    window.scrollTo({ top: 0 });
  }
}

function CustomApp({ Component, pageProps, err }: Props) {
  const router = useRouter();

  const queryClientRef = useRef<QueryClient>();
  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient();
  }

  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      gtag.pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      {gtag.GTM_ID && (
        <>
          <Script
            id='google-analytics'
            dangerouslySetInnerHTML={{
              __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${gtag.GTM_ID}');
              `,
            }}
          ></Script>
        </>
      )}
      <GlobalStyle />
      <QueryClientProvider client={queryClientRef.current}>
        <Hydrate state={pageProps.dehydratedState}>
          <AnimatePresence exitBeforeEnter onExitComplete={handleExitComplete}>
            <Component {...pageProps} err={err} key={router.asPath} />
          </AnimatePresence>
        </Hydrate>
      </QueryClientProvider>
    </>
  );
}

export default CustomApp;
