export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  if (window.gtag) {
    window.gtag('event', 'page_view', {
      page_location: url,
    });
  }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, body }) => {
  if (window.gtag) {
    window.gtag('event', action, body);
  }
};
