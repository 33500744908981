import { createGlobalStyle } from 'styled-components';
import { breakpoints, generateCustomPropertiesFromTheme } from './theme';

const GlobalStyle = createGlobalStyle`
  :root {
    /* Custom Properties */
    ${generateCustomPropertiesFromTheme()}
    --space-outer: var(--space-2);

    /* Adjusted type scale based on breakpoint */
    @media screen and (min-width: ${breakpoints.small}) {
      --font-size-scale-ratio: 1.2;
      --space-outer: var(--space-3);
    }

    @media screen and (min-width: ${breakpoints.large}) {
      --font-size-scale-ratio: 1.25;
      --space-outer: var(--space-5);
    }
  }

  html {
    box-sizing: border-box;
    font-family: var(--font-body);
    line-height: var(--line-height-large);
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
    color: inherit;
    font-family: inherit;
    overflow-wrap: break-word;
    background-color: inherit;
    border:0 solid;
  }

  /* Disable outline for non-keyboard users: https://css-tricks.com/the-focus-visible-trick/ */
  :focus:not(:focus-visible) { outline: none }
`;

export default GlobalStyle;
