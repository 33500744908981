import bbox from '@turf/bbox';
import { lineString } from '@turf/helpers';
import { FitBounds } from 'react-mapbox-gl/lib/map';

export const __DEV__ = process.env.NEXT_PUBLIC_ENV === 'development';

export const fetchData = async (input: RequestInfo, init?: RequestInit) => {
  const res = await fetch(input, init);
  return await res.json();
};

export function kebabCase(str: string) {
  return str.replace(/[A-Z\u00C0-\u00D6\u00D8-\u00DE]/g, function (match) {
    return '-' + match.toLowerCase();
  });
}

export const isBrowser = typeof window !== 'undefined';

export function hexToRGB(h: string) {
  let r: any = 0,
    g: any = 0,
    b: any = 0;

  // 3 digits
  if (h.length === 4) {
    r = '0x' + h[1] + h[1];
    g = '0x' + h[2] + h[2];
    b = '0x' + h[3] + h[3];

    // 6 digits
  } else if (h.length === 7) {
    r = '0x' + h[1] + h[2];
    g = '0x' + h[3] + h[4];
    b = '0x' + h[5] + h[6];
  }

  return +r + ',' + +g + ',' + +b;
}

export function getBoundsFromLocations(
  locations: Sanity.Schema.Location[]
): FitBounds {
  const boundingBox = bbox(
    lineString(
      locations?.map(({ mapLocation }) => [
        mapLocation?.lng || 0,
        mapLocation?.lat || 0,
      ])
    )
  );

  return [
    [boundingBox[0], boundingBox[1]],
    [boundingBox[2], boundingBox[3]],
  ];
}
